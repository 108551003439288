<template>
  <div class="container-fluid page-title">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1>Member Search</h1>
      </div>
    </div>
    <div class="container-fluid justify-content-between">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <search-patient
                  ref="search-patient-comp"
                  v-model="form.search_type"
                  @searchPatient="showPatientInfo"
                  :search_types="search_types"
                  :form="form"/>
                <div v-if="patient">
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <personal-information :data="patient" @patientUpdated="showPatientInfo"/>
                    </b-col>
                  </b-row>

                  <hr>

                  <b-row class="mb-5">
                    <b-col cols="12">
                      <b-tabs content-class="mt-5" fill>

                        <b-tab :active="!videoUUID">
                          <template #title>
                            <b-spinner type="border" small v-if="loadingPrograms"></b-spinner>
                            Program
                          </template>
                          <program-information :data="patient"/>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <b-spinner type="border" small v-if="loadingTherapist"></b-spinner>
                            Therapist
                          </template>
                          <therapist-information :data="patient"/>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <b-spinner type="border" small v-if="loadingCard"></b-spinner>
                            Card
                          </template>
                          <card-assigns v-if="patient.card_assignees && patient.card_assignees.length > 0"
                                        :cards="patient.card_assignees"/>
                          <div v-else>No cards associated</div>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <b-spinner type="border" small v-if="loadingDevices"></b-spinner>
                            Devices
                          </template>
                          <hardware-information v-if="patient.devices && patient.devices.length > 0"
                                                :data="patient"/>
                          <div v-else>No devices associated</div>
                        </b-tab>
                        <b-tab>
                          <template #title>
                            <b-spinner type="border" small v-if="loadingPrescriptions"></b-spinner>
                            Prescriptions
                          </template>
                          <prescriptions v-if="patient.prescriptions && patient.prescriptions.length > 0"
                                         :prescriptions="patient.prescriptions"/>
                          <div v-else>No prescriptions associated</div>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            <b-spinner type="border" small v-if="loadingStates"></b-spinner>
                            States
                          </template>
                          <div>
                            <transition-states
                              @stateChanged="showPatientInfo"
                              :previousStates="patient.previousStates"
                              :transitionStates="patientTransitions"
                              :patient="patient.user"/>
                          </div>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            Records
                          </template>
                          <div>
                            <div v-if="patient.user">
                              <records :id="patient.user.id"
                                       :memberAccountId="patient.user.member_account_id"
                                       :forms="patientForms"
                                       :country="patient.user.country"/>
                            </div>
                          </div>
                        </b-tab>


                        <b-tab>
                          <template #title>
                            Orders
                          </template>
                          <div>
                            <div class="row" v-if="patient.user">
                              <orders :id="patient.user.id"
                                      :countryStateCode="patient.user.geographical_state"
                                      :clientID="patient.user.client_id"/>
                            </div>
                          </div>
                        </b-tab>

                        <b-tab>
                          <template #title>
                            Ecosystem Events
                          </template>
                          <div>
                            <div v-if="showEcosystem">
                              <ecosystem-events :user="patient.ecosystem_integration.user" :events="patient.ecosystem_integration.events"/>
                            </div>
                          </div>
                        </b-tab>

                        <b-tab :active="!!videoUUID">
                          <template #title>
                            Videos
                          </template>
                          <div>
                            <div v-if="videos.length">
                              <videos :videos="videos" :highlighted-video-uuid="videoUUID" />
                            </div>
                          </div>
                        </b-tab>

                      </b-tabs>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="loading">
                  <loading-content label="Loading Information..."/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as R from 'ramda';

import AccountDeletedError from '@/scripts/errors/AccountDeletedError';
import CardAssigns from '@/views/OpsSearchProgram/partials/CardAssigns.vue';
import HardwareInformation from '@/views/OpsSearchProgram/partials/HardwareInformation.vue';
import PersonalInformation from '@/views/OpsSearchProgram/partials/PersonalInformation.vue';
import ProgramInformation from '@/views/OpsSearchProgram/partials/ProgramInformation.vue';
import TherapistInformation from '@/views/OpsSearchProgram/partials/TherapistInformation.vue';
import TransitionStates from '@/views/OpsSearchProgram/partials/TransitionStates.vue';
import Records from '@/views/OpsSearchProgram/partials/Records.vue';
import LoadingContent from '@/components/LoadingContent.vue';
import SearchPatient from '@/views/OpsSearchProgram/partials/SearchPatient.vue';
import Orders from '@/views/OpsSearchProgram/partials/Orders.vue';
import EcosystemEvents from '@/views/OpsSearchProgram/partials/EcosystemEvents.vue';
import Videos from '@/views/OpsSearchProgram/partials/Videos.vue';
import Prescriptions from '@/views/OpsSearchProgram/partials/Prescriptions.vue';

const SEARCH_ACCOUNT_UUID = 'account_uuid';
const SEARCH_PROGRAM_UUID = 'program_uuid';
const SEARCH_PROGRAM_ID = 'program_id';
const SEARCH_EMAIL = 'email';
const SEARCH_PHONE = 'phone';
const SEARCH_QR_CARD_TYPE = 'qr_card';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'ops-search-member',
  components: {
    prescriptions: Prescriptions,
    'card-assigns': CardAssigns,
    'hardware-information': HardwareInformation,
    'personal-information': PersonalInformation,
    'program-information': ProgramInformation,
    'therapist-information': TherapistInformation,
    'loading-content': LoadingContent,
    'transition-states': TransitionStates,
    records: Records,
    'search-patient': SearchPatient,
    orders: Orders,
    'ecosystem-events': EcosystemEvents,
    videos: Videos,
  },
  data() {
    return {
      loading: false,
      loadingPrograms: false,
      loadingTherapist: false,
      loadingCard: false,
      loadingDevices: false,
      loadingPrescriptions: false,
      loadingStates: false,
      patient: null,
      patientTransitions: null,
      patientForms: null,
      videos: [],
      videoUUID: null,
      form: {
        search_value: null,
        search_type: SEARCH_ACCOUNT_UUID,
      },
      search_types: [
        {
          value: SEARCH_ACCOUNT_UUID,
          text: 'Account UUID',
          value_label: 'Account UUID',
          validation_regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
        },
        {
          value: SEARCH_PROGRAM_UUID,
          text: 'Program UUID',
          value_label: 'Program UUID',
          validation_regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
        },
        {
          value: SEARCH_PROGRAM_ID,
          text: 'Program ID',
          value_label: 'Program ID',
          validation_regex: /\d/,
        },
        {
          value: SEARCH_EMAIL,
          text: 'Email',
          value_label: 'Member email',
          validation_regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        {
          value: SEARCH_QR_CARD_TYPE,
          text: 'QR Card',
          value_label: 'QR Card Number',
        },
        {
          value: SEARCH_PHONE,
          text: 'Phone Number',
          value_label: 'Member phone number',
        },
      ],
    };
  },
  computed: {
    showEcosystem() {
      return !!this.patient?.ecosystem_integration?.user;
    },
  },
  methods: {
    showPatientInfo(form) {
      let searchType = this.form.search_type;
      if (form) {
        searchType = form.search_type;
      }

      this.loading = true;
      this.patient = null;
      this.patientTransitions = null;

      this.fetchInformation(searchType).then(patientResponse => {
        const userId = patientResponse.data.user.id;

        this.processAddressLine(patientResponse.data.user);

        return Promise.all([
          this.getPatientStateAndTransitions(userId),
          this.getPatientAccountStatus(userId),
          this.getPatientForms(userId),
          this.getPatientOpticalTherapyMedia(userId),
        ]).then(result => {
          const [
            patientStateAndTransitions,
            patientIsLocked,
            patientForms,
            videos,
          ] = result;

          this.patient = patientResponse.data;
          this.patient.previousStates = patientStateAndTransitions.data.current_state.transition_history;
          this.patient.isLocked = patientIsLocked.data.is_locked;
          this.patientTransitions = patientStateAndTransitions.data.possible_transitions;
          this.patientForms = patientForms.data;
          this.videos = videos?.data || [];
        });
      }).catch(err => {
        if (R.path([ 'response', 'data', 'message' ], err)) {
          this.$noty.error(err.response.data.message);
        } else if (AccountDeletedError.matchesFormat(err)) {
          this.$noty.error(new AccountDeletedError().message);
        } else {
          this.$noty.error(R.propOr('Failed to load information.', 'message', err));
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    processAddressLine(user) {
      let addressParts = user.address_line_1.split(', ');

      [ 'country', 'geographical_state', 'city', 'zip_code', 'address_line_2' ].forEach(key => {
        if (!isNullOrEmpty(R.prop(key, user))) {
          addressParts = R.filter(part => part !== R.prop(key, user), addressParts);
        }
      });

      user.address_line_1 = addressParts.length > 0 ? addressParts[0] : user.address_line_1;
    },
    async fetchInformation(type) {
      if (type === SEARCH_QR_CARD_TYPE) {
        return axios.get(`/v1/cards/${this.form.search_value}`);
      }

      const queryString = `field=${this.form.search_type}&value=${encodeURIComponent(this.form.search_value)}`;
      return axios.get(`/v2/patients/first?${queryString}`);
    },
    getPatientForms(id) {
      return axios.get(`/v1/patients/${id}/forms`);
    },
    getPatientStateAndTransitions(id) {
      return axios.get(`/v1/patients/${id}/state`, {
        params: {
          include_history: true,
        },
      });
    },
    getPatientAccountStatus(id) {
      return axios.get(`/v1/patients/${id}/account-status`);
    },
    getPatientOpticalTherapyMedia(id) {
      return axios.get('/v1/optical-therapy/media', {
        params: {
          patient_id: id,
        },
      });
    },
    automaticSearchByQuery() {
      if (!this.$route.query) {
        return;
      }

      this.videoUUID = this.$route.query.video_uuid;

      [
        SEARCH_ACCOUNT_UUID,
        SEARCH_PROGRAM_UUID,
        SEARCH_PROGRAM_ID,
        SEARCH_EMAIL,
        SEARCH_PHONE,
        SEARCH_QR_CARD_TYPE,
      ].forEach(type => {
        if (!this.$route.query[type]) {
          return;
        }
        this.form.search_type = type;
        this.form.search_value = this.$route.query[type];
        this.showPatientInfo();
      });
    },
  },
  mounted() {
    this.automaticSearchByQuery();
  },
};
</script>

<style scoped>
.page-title {
  margin-top: 1%;
}
</style>
