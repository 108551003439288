import { render, staticRenderFns } from "./Prescriptions.vue?vue&type=template&id=641f1d90&scoped=true"
import script from "./Prescriptions.vue?vue&type=script&lang=js"
export * from "./Prescriptions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641f1d90",
  null
  
)

export default component.exports