<template>
  <b-row>
    <b-col cols="12">
      <table class="table">
        <thead class="thead-light">
        <tr class="row">
          <th scope="col" class="col-md-3"><b>Description</b></th>
          <th scope="col" class="col-md-3"><b>Group</b></th>
        </tr>
        </thead>
        <tbody>
        <tr class="row" v-for="(prescription, index) in prescriptions" :key="index">
          <td class="col-md-3">{{ prescription.description }}</td>
          <td class="col-md-3">{{ prescription.group }}</td>
        </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'prescriptions',
  props: {
    prescriptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
</style>
