<template>
  <div class="d-flex flex-column align-items-center">
    <b-spinner class="mb-2" label="Loading..." variant="info" size="lg" ></b-spinner>
    <p v-if="label"> {{ label }} </p>
  </div>
</template>

<script>
export default {
  name: 'LoadingContent',
  props: {
    label: String,
  },
};
</script>

<style lang="scss" scoped>
</style>
