export default class AccountDeletedError {
  static ERROR_CODES = [ 10100, 12000 ];

  constructor(error) {
    this.name = 'AccountDeletedError';
    this.message = 'Account has been deleted';
    this.response = undefined;
    this.status = undefined;
    this.code = undefined;
    this.data = undefined;
    this.parsePayload(error);
  }

  parsePayload(error) {
    if (!error) return;

    const errorResponse = error.response;

    if (errorResponse) {
      this.response = errorResponse;
      this.status = errorResponse.status;

      if (errorResponse.data) {
        this.code = errorResponse.data.error_code;
        this.data = errorResponse.data;
      }
    }
  }

  static matchesFormat(error) {
    let errorCode = null;

    if (error.response && error.response.data) {
      errorCode = error.response.data.error_code;
    }

    if (!errorCode) return false;

    const hasAccountDeleteErrorCode = AccountDeletedError.ERROR_CODES.includes(errorCode);

    return hasAccountDeleteErrorCode;
  }
}
