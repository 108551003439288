<template>
  <div>
    <b-form>
      <b-spinner type="border" small v-if="saving"></b-spinner>
      <b-row>
        <b-col lg="6">
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4" label-cols-lg="3"
              label="Account UUID"
              label-for="input-account-uuid">
            <b-form-input id="account-uuid" size="sm" disabled v-model="data.user.member_account_id"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="First name"
              label-for="user-first-name">
            <b-form-input id="user-first-name" size="sm" disabled v-model="data.user.first_name"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Last name"
              label-for="user-last-name">
            <b-form-input id="user-last-name" size="sm" disabled v-model="data.user.last_name"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Phone"
              label-for="user-phone">
            <b-form-input
                id="user-phone"
                size="sm"
                :disabled="editing === false"
                v-model="data.user.patient_phone">
            </b-form-input>
            <input-feedback-message
                data-testid="userPhoneInputMessageSMSComplaint"
                v-if="data.user.patient_phone_can_receive_sms === 0"
                class="text-info"
                message="The number is not eligible for SMS"
                icon-name="alert-triangle" />
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Email"
              label-for="user-email">
            <b-form-input id="user-email" size="sm" disabled v-model="data.user.patient_email"></b-form-input>
          </b-form-group>
          <b-form-group
              v-if="!editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Address"
              label-for="user-address">
            <b-form-input id="user-address" size="sm" disabled v-model="userAddress"></b-form-input>
          </b-form-group>
          <b-form-group
              v-if="editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Address line 1"
              label-for="user-address-line-1">
            <b-form-input id="user-address-line-1" size="sm" v-model="data.user.address_line_1"></b-form-input>
          </b-form-group>
          <b-form-group
              v-if="editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Address line 2"
              label-for="user-address-line-2">
            <b-form-input id="user-address-line-2" size="sm" v-model="data.user.address_line_2"></b-form-input>
          </b-form-group>
          <b-form-group
              v-if="editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Zip code"
              label-for="user-address-zip-code">
            <b-form-input id="user-address-zip-code" size="sm" v-model="data.user.zip_code"></b-form-input>
          </b-form-group>
          <b-form-group
              v-if="editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="City"
              label-for="user-address-city">
            <b-form-input id="user-address-city" size="sm" v-model="data.user.city"></b-form-input>
          </b-form-group>
          <b-form-group
              v-if="editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="State"
              label-for="user-address-state">
            <b-form-select
                id="user-address-state"
                size="sm"
                v-model="data.user.geographical_state"
                :disabled="!countryGeoStateSupported"
                :options="geographicalStateOptions">
            </b-form-select>
          </b-form-group>
          <b-form-group
              v-if="editing"
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Country"
              label-for="user-address-country">
            <b-form-select
                id="user-address-country"
                size="sm"
                @change="onCountryChange"
                v-model="data.user.country"
                :options="countryOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Program ID"
              label-for="input-id">
            <b-form-input id="input-id" size="sm" disabled v-model="data.user.id"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Program UUID"
              label-for="input-id">
            <b-form-input id="input-id" size="sm" disabled v-model="data.user.uuid"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Onboarding Call Status"
              label-for="input-onboarding-call-status">
            <b-form-input id="input-onboarding-call-status" size="sm" disabled v-model="onboardingVideoCallStatus"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="font-weight-bold"
              label-cols="4"
              label-cols-lg="3"
              label="Onboarding Video Call"
              label-for="input-enrollment-id">
            <b-form-input id="input-id" size="sm" disabled v-model="onboardingVideoCall"></b-form-input>
          </b-form-group>
          <router-link class="btn btn-primary m-1"
                       :to="`/ops/member-change-owner/${data.user.id}`">
                          Manage ownership and shares >
          </router-link>
          <b-button class="btn btn-danger m-1"
                    :disabled="passwordReset.disabled"
                    @click="sendPasswordResetEmail">
            <b-spinner v-if="passwordReset.loading" label="Sending..." variant="light" small></b-spinner>
            <span v-if="!passwordReset.loading">Send password reset email</span>
          </b-button>
          <b-button v-b-tooltip.hover
                    v-if="data.isLocked && !isUnlockSent"
                    class="btn btn-danger m-1"
                    id="buttonUnlockAccount"
                    @click="unlockAccount(data.user.id)">
                    Unlock account
          </b-button>
          <b-button v-if="data.appointment_uuid && data.user.origin && onboardingVideoCallStatus != 'SUBMITTED'"
             target="_blank"
             class="btn btn-success m-1"
             :href="getRescheduleURL(data.appointment_uuid)">
            Go to reschedule page
          </b-button>
          <b-button v-if="data.appointment_uuid && data.user.origin && onboardingVideoCallStatus != 'SUBMITTED'"
             target="_blank"
             class="btn btn-success m-1"
             :href="getEmergencyCoverageURL(data.appointment_uuid)">
            Emergency coverage
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-row>
      <b-col cols="12">
        <b-button :disabled="saving"
                  v-if="editing"
                  title="Cancel"
                  size="sm"
                  variant="danger"
                  @click="cancel()">
          Cancel
        </b-button>
        <b-button :disabled="saving"
                  v-if="editing"
                  title="Save"
                  size="sm"
                  variant="primary"
                  class="ml-2"
                  @click="save()">
          <b-spinner label="Saving..." variant="light" small v-if="saving"></b-spinner>
          <span v-else>Save</span>
        </b-button>
        <b-button
                  v-if="!editing"
                  title="Edit"
                  size="sm"
                  variant="info"
                  @click="edit()">
          Edit <feather type="edit-2"></feather>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
import * as R from 'ramda';
import { mapGetters } from 'vuex';
import { definitions } from '@sword-health/ui-core';
import InputFeedbackMessage from '@/components/InputFeedbackMessage.vue';

const countryOptions = definitions.swordCountriesList.map(country => ({
  text: country.name,
  value: country.code.toUpperCase(),
}));

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

const getAddress = R.compose(
  R.join(', '),
  R.reject(isNullOrEmpty),
  R.props([ 'address_line_1', 'address_line_2', 'zip_code', 'city', 'geographical_state', 'country' ]),
);

export default {
  name: 'personal-information',
  components: {
    'input-feedback-message': InputFeedbackMessage,
  },
  methods: {
    formatDatetime(datetime) {
      if (!datetime) {
        return '-';
      }

      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (_) {
        return datetime;
      }
    },
    async unlockAccount(userid) {
      const yes = await this.$bvModal.msgBoxConfirm('Unlock account ?');
      if (!yes) {
        return;
      }
      try {
        await axios.post('/v1/cards/reset', { user_id: userid });
        this.$noty.success('Account successfully unlocked!');
        this.isUnlockSent = true;
      } catch (error) {
        this.$noty.error('failed to unlock account', error);
        console.error(error);
      }
    },
    sendPasswordResetEmail() {
      if (this.passwordReset.loading) {
        return;
      }
      this.passwordReset.disabled = true;
      this.passwordReset.loading = true;
      axios.post('v1/onboarding/members/password-recover', { account_id: this.patient.member_account_id })
        .then(() => this.$noty.success('Password recover email sent', { timeout: 750, progressBar: false }))
        .catch(() => this.$noty.error('An unknown error ocurred, please try again'))
        .finally(() => {
          this.passwordReset.disabled = false;
          this.passwordReset.loading = false;
        });
    },
    edit() {
      this.editing = true;
    },
    cancel() {
      this.editing = false;

      // reset any changes
      this.data.user = {
        ...this.data.user,
        ...this.patient,
      };
    },
    save() {
      this.saving = true;

      axios.put(
        `/v1/patients/${this.data.user.id}`,
        this.buildPayload(),
      ).then(() => {
        this.$emit('patientUpdated');
        this.$noty.success('Patient updated successfully');
      }).catch(e => {
        let msg = 'Failed to update Patient.';
        if (e.response.data.error_description) {
          msg += ` (${JSON.stringify(e.response.data.error_description)})`;
        }
        this.$noty.error(msg);
        console.error(e);
      }).finally(() => {
        this.saving = false;
      });
    },
    buildPayload() {
      return {
        user_id: this.data.user.id,
        telephone: this.data.user.patient_phone,
        user_metadata: {
          address_line_1: this.data.user.address_line_1,
          address_line_2: this.data.user.address_line_2,
          zip_code: this.data.user.zip_code,
          city: this.data.user.city,
          country: this.data.user.country,
          geographical_state: this.data.user.geographical_state,
        },
      };
    },
    onCountryChange() {
      this.data.user.geographical_state = '';
    },
  },
  data() {
    return {
      passwordReset: {
        disabled: false,
        loading: false,
      },
      isUnlockSent: false,
      editing: false,
      saving: false,
      countryOptions,
      patient: { ...this.data.user },
      userAddress: getAddress(this.data.user),
    };
  },
  computed: {
    ...mapGetters({
      getRescheduleURL: 'Core/getRescheduleURL',
      getEmergencyCoverageURL: 'Core/getEmergencyCoverageURL',
    }),
    onboardingVideoCall: {
      get() {
        if (this.onboardingVideoCallStatus === 'SUBMITTED') {
          return this.formatDatetime(this.data.user.onboarding_video_call);
        }
        return '-';
      },
    },
    onboardingVideoCallStatus: {
      get() {
        return this.data.user.onboarding_video_call_stauts;
      },
    },
    geographicalStateOptions() {
      const countryStates = definitions.getCountryStateOptions(this.data.user.country);
      if (!countryStates) {
        return countryStates;
      }

      return definitions.getCountryStateOptions(this.data.user.country).map(countryState => ({
        text: countryState.optLabel,
        value: countryState.optValue,
      }));
    },
    countryGeoStateSupported() {
      return this.data.user.country && this.geographicalStateOptions && this.geographicalStateOptions.length;
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        user: {},
      }),
    },
  },
};
</script>
