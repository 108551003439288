<template>
  <b-row>
    <b-col cols="12">
      <table class="table">
        <thead class="thead-light">
        <tr class="row">
          <th scope="col" class="col-md-4"><b>Video Name</b></th>
          <th scope="col" class="col-md-4"><b>Uploaded at</b></th>
          <th scope="col" class="col-md-4"><b>Actions</b></th>
        </tr>
        </thead>
        <tbody>
        <tr :class="['row', { highlight: highlightedVideoUuid === video.uuid }]" v-for="(video, index) in videos" :key="index">
          <td class="col-md-4">{{ `${video.session_group_id}_${video.session_exercise_id}.zip` }}</td>
          <td class="col-md-4">{{ formatDate(video.created_at) }}</td>
          <td class="col-md-4">
            <b-button
              size="sm mr-2"
              variant="light"
              class="btn-action"
              @click="copyShareableLink(video.account_uuid, video.uuid)">
              Copy Link <feather type="clipboard"></feather>
            </b-button>
            <b-button
              size="sm"
              variant="info"
              class="btn-action"
              @click="downloadVideo(video)">
              Download <feather type="download"></feather>
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import utils from '@/scripts/tools/utils';
import copyToClipboard from '@/scripts/tools/accessibility';

export default {
  name: 'videos',
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    highlightedVideoUuid: {
      type: String,
    },
  },
  methods: {
    async downloadVideo(video) {
      try {
        const { data } = await axios.get(`v1/optical-therapy/media/${video.uuid}/download`);

        utils.downloadFile(data, this.getFilename(video));
      } catch (err) {
        const errorMessage = err?.response?.data?.message;
        if (errorMessage) {
          this.$noty.error(errorMessage);
        } else {
          this.$noty.error(`Error downloading: ${err.message || 'unknown error'}`);
        }
      }
    },
    formatDate(date) {
      return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    },
    getFilename(video) {
      return `${video.session_group_id}_${video.session_exercise_id}.zip`;
    },
    copyShareableLink(accountUUID, videoUUID) {
      const queryParams = new URLSearchParams({ accountUUID, videoUUID }).toString();

      const shareableLink = `${window.location.origin}/#${this.$router.history.current.path}?${queryParams}`;

      copyToClipboard(shareableLink);
      this.$noty.success('link copied to clipboard');
    },
  },
};
</script>

<style scoped lang="scss">
.highlight {
  background-color: $lemon-chiffon;
}
</style>
