<template>
  <b-row>
    <b-col cols="6">
      <p><b>Treatment Therapist ID:</b> {{data.user.owner_id}}</p>
      <div v-if="data.therapist">
        <p><b>Treatment therapist name:</b> {{getTherapistName(data.therapist)}}</p>
      </div>
    </b-col>
    <b-col cols="6">
      <div v-if="data.video_call_therapist">
        <p><b>Video call therapist ID:</b> {{data.video_call_therapist.user_id}}</p>
        <p><b>Video call therapist name:</b> {{getTherapistName(data.video_call_therapist)}}</p>
        <p><b>Video call:</b> {{data.video_call_therapist.video_call_url}}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import * as R from 'ramda';

export default {
  name: 'therapist-information',
  methods: {
    getTherapistName(therapist) {
      return R.compose(R.join(' '), R.props([ 'first_name', 'last_name' ]))(therapist);
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        user: {},
      }),
    },
  },
};
</script>
