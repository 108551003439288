<template>
  <div class="d-flex align-items-center">
    <feather :type="iconName"></feather>
    <span class="ml-1">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'InputFeedbackMessage',
  props: {
    iconName: String,
    message: String,
  },
};
</script>
