<template>
  <b-row>
    <b-col cols="12">
      <table class="table">
        <thead class="thead-light">
        <tr class="row">
          <th scope="col" class="col-md-3"><b>Card Number</b></th>
          <th scope="col" class="col-md-3"><b>Created</b></th>
          <th scope="col" class="col-md-3"><b>Updated</b></th>
          <th scope="col" class="col-md-3"><b>Deleted</b></th>
        </tr>
        </thead>
        <tbody>
        <tr class="row" v-for="card in cards" :key="card.card_id">
          <td class="col-md-3">{{ card.card_id }}</td>
          <td class="col-md-3">{{ formatDatetime(card.created_at) }}</td>
          <td class="col-md-3">{{ formatDatetime(card.updated_at) }}</td>
          <td class="col-md-3">{{ formatDatetime(card.deleted_at) }}</td>
        </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'card-assigns',
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatDatetime(datetime) {
      if (!datetime) {
        return 'active';
      }

      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (_) {
        return datetime;
      }
    },
  },
};
</script>
