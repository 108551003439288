<template>
  <b-row>
    <b-col cols="12">
      <p><b>Tablet SN:</b> {{lastDevice.serial_number}}</p>
      <p><b>Card number:</b> {{data.card.number}}</p>
      <p><b>Android version:</b> {{lastDevice.configs.os_version}}</p>
      <p><b>Tablet IMEI:</b> {{lastDevice.imei}}</p>
      <p><b>Tablet IMEI:</b> {{lastDevice.imei}}</p>
      <p><b>Trackers set:</b> {{lastDevice.configs.trackers_set}}</p>
      <p><b>Firmware version:</b> {{lastDevice.configs.firmware}}</p>
      <p><b>QR code:</b> {{data.card.qr_code}}</p>
      <p><b>Kit version:</b> {{lastDevice.configs.kit_version}}</p>
      <p><b>Hotspot:</b> {{lastDevice.configs.hotspot}}</p>
      <div v-if="data.urls"><b>Metabase:</b>
        <b-button class="btn btn-success btn-block" :href="data.urls.exercises">
          Exercises Metrics
        </b-button>
        <b-button class="btn btn-success btn-block" :href="data.urls.tablet">
          Battery Information
        </b-button>
        <b-button class="btn btn-success btn-block" :href="data.urls.mts">
          MTS
        </b-button>
        <b-button class="btn btn-success btn-block" :href="data.urls.breadcrumbs">
          Breadcrumbs
        </b-button>
      </div>

      <div class="mt-5" v-if="data.devices">
        <table class="table">
          <thead class="thead-light">
          <tr class="row">
            <th scope="col" class="col-md-3"><b>Table SN</b></th>
            <th scope="col" class="col-md-3"><b>Card Number</b></th>
            <th scope="col" class="col-md-2"><b>Android version</b></th>
            <th scope="col" class="col-md-2"><b>Firmware version</b></th>
            <th scope="col" class="col-md-2"><b>Created At</b></th>
          </tr>
          </thead>
          <tbody>
          <tr class="row" v-for="device in data.devices" :key="device.id">
            <td class="col-md-3 sn-column">{{ device.serial_number }}</td>
            <td class="col-md-3 cn-column">{{ device.card_number }}</td>
            <td class="col-md-2 os-version-column">{{ device.configs.os_version }}</td>
            <td class="col-md-2 firmware-column">{{ device.configs.firmware }}</td>
            <td class="col-md-2 requested-column">{{ formatDatetime(device.created_at) }}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment-timezone';

export default {
  name: 'hardware-information',
  props: {
    data: {
      type: Object,
      default: () => ({
        card: {},
        device: {},
      }),
    },
  },
  computed: {
    lastDevice() {
      return this.data.devices[0];
    },
  },
  methods: {
    formatDatetime(datetime) {
      if (!datetime) {
        return 'active';
      }

      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (_) {
        return datetime;
      }
    },
  },
};
</script>
