<template>
  <div>
    <b-row class="mb-5">
    <b-col cols="6">
      <b-button :disabled="loading"
                class="btn btn-success btn-block"
                @click="downloadForms">
        Download clinical forms
      </b-button>
      <b-button :disabled="loading"
                class="btn btn-success btn-block"
                @click="downloadChat">
        Download chat
      </b-button>
      <div v-if="this.memberAccountId && this.country && this.country.toUpperCase()==='US'">
      <b-row>
        <b-col class="mt-3" cols="12">
          <p><b>Health Information Exchanges:</b> {{getAllowedShareClinicalData}}</p>
        </b-col>
      </b-row>
      <b-button :disabled="!getAllowedByClient"
                class="button-provider-integration btn btn-success btn-block"
                @click="updateShareClinicalData">
        {{renderProviderIntegrationButtonText}}
      </b-button>
      </div>
    </b-col>
    </b-row>

    <b-row class="col-12">
      <b-col cols="12">
      <table class="table">
        <thead class="thead-light">
        <tr>
          <th scope="col" class="text-center"><b>Name</b></th>
          <th scope="col" class="text-center"><b>Days With Sessions</b></th>
          <th scope="col" class="text-center"><b>Number of Sessions</b></th>
          <th scope="col" class="text-center"><b>Status</b></th>
          <th scope="col" class="text-center"><b>Submitted At</b></th>
          <th scope="col" class="text-center"><b>Create At</b></th>
          <th scope="col" class="text-center"><b>Form Link</b></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="form in forms" :key="`${form.id}`">
          <td class="text-center">{{form.name}}</td>
          <td class="text-center">{{form.daysWithSessions}}</td>
          <td class="text-center">{{form.numberOfSessions}}</td>
          <td class="text-center">{{form.status}}</td>
          <td class="text-center">{{formatDate(form.submittedAt, 'MMMM DD YYYY, hh:mm')}}</td>
          <td class="text-center">{{formatDate(form.createdAt, 'MMMM DD YYYY, hh:mm')}}</td>
          <td class="text-center">
                <b-button
                  size="sm"
                  variant="light"
                  class="btn-action"
                  @click="copyFormLink(form.link)">
                  Copy Link <feather type="clipboard"></feather>
                </b-button>
          </td>
        </tr>
        </tbody>
      </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import copyToClipboard from '../../../scripts/tools/accessibility';

export default {
  name: 'records',
  data() {
    return {
      loading: false,
      allowShareClinicalData: false,
      allowedByClient: true,
    };
  },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    memberAccountId: {
      type: String,
      default: () => '',
    },
    forms: {
      type: Array,
      default: () => [],
    },
    country: {
      type: String,
      default: () => '',
    },
  },
  created() {
    if (this.memberAccountId && this.country && this.country.toUpperCase() === 'US') {
      this.getShareClinicalData();
    }
  },
  computed: {
    renderProviderIntegrationButtonText() {
      if (this.allowedByClient) {
        return this.allowShareClinicalData ? 'Disable Health Information Exchanges' : 'Enable Health Information Exchanges';
      }
      return 'HIE disabled by the client';
    },
    getAllowedByClient() {
      return this.allowedByClient;
    },
    getAllowedShareClinicalData() {
      if (this.allowedByClient) {
        return this.allowShareClinicalData;
      }
      return false;
    },
  },
  methods: {
    downloadForms() {
      this.loading = true;
      axios.get(`/v1/users/${this.id}/forms/zip`, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.id}-forms.zip`);
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$noty.error(`Patient has no forms submitted. ${err.message}`);
      });
    },
    downloadChat() {
      this.loading = true;
      axios.get(`v1/users/${this.memberAccountId}/chat`, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.memberAccountId}-chat.txt`);
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$noty.error(`Patient has no chat msg.${err.message}`);
      });
    },
    formatDate(date, format, tz) {
      let parsedDate = moment(date);
      if (tz) {
        parsedDate = moment.tz(date, tz);
      } else {
        parsedDate.utcOffset(date);
      }

      if (!parsedDate.isValid()) {
        return '-';
      }

      return parsedDate.format(format);
    },
    getShareClinicalData() {
      return axios.get(`v2/users/${this.memberAccountId}/allow-share-clinical-data`)
        .then(response => {
          this.allowShareClinicalData = Boolean(response.data.patient_share_clinical_data);
          this.allowedByClient = Boolean(response.data.client_share_clinical_data);
        }).catch(err => {
          console.error(err);
        });
    },
    copyFormLink(link) {
      copyToClipboard(link);
      this.$noty.success('url copied to clipboard');
    },
    async updateShareClinicalData() {
      let integrationLabels;
      if (this.allowShareClinicalData) {
        integrationLabels = {
          title: 'Disable Health Information Exchange Integration',
          message: 'Are you sure you would like to disable the Health Information Exchange Integration for this member?',
          okTitle: 'Disable Integration',
        };
      } else {
        integrationLabels = {
          title: 'Enable Health Information Exchange Integration',
          message: 'Are you sure you would like to enable the Health Information Exchange Integration for this member?',
          okTitle: 'Enable Integration',
        };
      }
      const yes = await this.$bvModal.msgBoxConfirm(integrationLabels.message,
        {
          centered: true,
          title: integrationLabels.title,
          okTitle: integrationLabels.okTitle,
        });
      if (!yes) {
        return;
      }
      await axios.put(`v1/users/${this.memberAccountId}/allow-share-clinical-data`,
        { allow: !this.allowShareClinicalData });
      this.allowShareClinicalData = !this.allowShareClinicalData;
    },
  },
};
</script>


<style scoped>
.button-provider-integration {
  min-width: 210px;
}
</style>
