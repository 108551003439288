<template>
  <b-row>
    <b-col cols="6">
      <p><b>Origin:</b> {{data.user.origin}}</p>
      <p><b>Kit:</b> {{data.user.kit}}</p>
      <p><b>Therapy:</b> {{data.user.therapy}}</p>
      <p><b>Protocol:</b> {{data.user.protocol}}</p>
      <p><b>Therapist ID:</b> {{data.user.owner_id}}</p>
      <p><b>Type:</b> {{data.user.tags}}</p>
      <p><b>Institution:</b> {{data.user.institution}}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'program-information',
  props: {
    data: {
      type: Object,
      default: () => ({
        user: {},
      }),
    },
  },
};
</script>
