<template>
  <div>
    <div class="row">
      <div class="col-md-3 sh-input-wrapper">
        <label>Search by</label>
        <b-form-select v-model="form.search_type" name="search_type" :options="search_types"/>
      </div>
      <div class="col-md-3 sh-text-wrapper sh-input-wrapper">
        <label v-if="searchLabel">{{ searchLabel.toUpperCase() }}</label>
        <b-form-input type="text" v-model="form.search_value" name="search_value" :placeholder="searchLabel" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <button
            id="submit-patient-search"
            type="submit"
            :disabled="loading"
            class="btn btn-info btn-fill float-left"
            @click.prevent="validateForm">
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'search',
  props: {
    search_types: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    searchLabel() {
      const searchType = this.search_types.find(element => element.value === this.form.search_type);

      return searchType ? searchType.value_label : 'undefined';
    },
  },
  methods: {
    validateForm() {
      if (!this.form.search_value) {
        this.$noty.error('You must enter a search value');
        return;
      }

      if (!this.form.search_type) {
        this.$noty.error('You must select a search type');
        return;
      }

      const searchType = this.search_types.find(element => element.value === this.form.search_type);

      if (!searchType) {
        this.$noty.error('You inserted an invalid search type.');
        return;
      }

      if (searchType && searchType.validation_regex && !searchType.validation_regex.test(this.form.search_value)) {
        this.$noty.error(`You have entered an invalid ${searchType.label}!`);
        return;
      }

      this.$emit('searchPatient', this.form);
    },
  },
};
</script>

<style scoped>
</style>
