<template>
  <div class="col-md-12">
    <collapsable-title id="collapsable-title-orders" target="orders" title="Orders" />
    <div id="orders" class="panel-collapse collapse">
      <div class="row">
        <div class="col-md-12"></div>
      </div>
    </div>
    <div class="text-left">
      <button
        id="submit-patient-search"
        type="submit"
        :disabled="loading"
        class="btn btn-info btn-fill float-left"
        @click="createManualOrder">
        Create Order
      </button>
    </div>
    <div class="patient_search_orders_holder orders-list-holder">
      <orders-list :tagModule="'PATIENT_SEARCH'" :tagId="this.id" id="module_orders_list_id"></orders-list>
    </div>
  </div>
</template>

<script>
import OrdersList from '@/components/OrderManagement/Orders/OrdersList.vue';

export default {
  name: 'orders',
  components: {
    'orders-list': OrdersList,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    clientID: {
      type: Number,
      default: () => 0,
    },
    countryStateCode: {
      type: String,
    },
  },
  methods: {
    createManualOrder() {
      this.$router.push({
        name: 'NewManualOrder',
        params: {
          patientId: this.id,
          clientID: this.clientID,
          countryStateCode: this.countryStateCode,
        },
      }).catch(e => e);
    },
  },
};
</script>

<style scoped>
  .patient_search_orders_holder{
    display: block;
    margin-top: 65px;
  }
</style>
