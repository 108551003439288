<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table" v-if="previousStates.length > 0">
        <thead class="thead-light">
        <tr class="row">
          <th scope="col" class="col-md-2"><b>Transition Date</b></th>
          <th scope="col" class="col-md-2"><b>From State</b></th>
          <th scope="col" class="col-md-2"><b>To State</b></th>
          <th scope="col" class="col-md-3"><b>Reason</b></th>
          <th scope="col" class="col-md-3"><b>Explanation</b></th>
          <th scope="col" class="col-md-3"><b>Attachments</b></th>
        </tr>
        </thead>
        <tbody>
        <tr class="row" v-for="state in previousStates" :key="`${state.from_state}_${state.to_state}`">
          <td class="col-md-2">{{ formatDatetime(state.created_at) }}</td>
          <td class="col-md-2">{{ state.current_state}}</td>
          <td class="col-md-2">{{ state.new_state }}</td>
          <td class="col-md-2">{{ state.reason }}</td>
          <td class="col-md-3">{{ state.explanation }}</td>
          <td class="col-md-1">
            <a v-if="state.attachments_url" :href="state.attachments_url" target="_blank">
              View file
            </a>
            <span v-else>&#8210;</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <select class="float-left form-control col-4" v-model="selectedState" :disabled="disableManualTransitions()">
        <option></option>
        <option v-for="state in transitionStates" :key="state" :value="state">
          {{ state }}
        </option>
      </select>

      <b-button @click="forceTransition" variant="primary" :disabled="disableManualTransitions()">Force State Transition</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'transition-states',
  data() {
    return {
      selectedState: null,
      unitsWithNoManualTransitions: [ 'move' ],
    };
  },
  props: {
    patient: null,
    previousStates: {
      type: Array,
      default: () => [],
    },
    transitionStates: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    disableManualTransitions() {
      if (!this.patient.unit) {
        return false;
      }
      return this.unitsWithNoManualTransitions.includes(this.patient.unit);
    },
    forceTransition() {
      if (this.disableManualTransitions()) {
        return;
      }
      this.saving = true;
      // Check if selectedState in transitionStates
      // Apply Change
      if (!this.transitionStates.includes(this.selectedState)) {
        Vue.prototype.$noty.error('Wrong selected state');
        return;
      }

      const payload = {
        patientId: this.patient.id,
        patientUUID: this.patient.uuid,
        state: this.selectedState,
        reason: 'manual.admin.ops',
        force_transition: true,
      };

      axios.post(`/v1/patients/${this.patient.id}/state`, payload).then(() => {
        this.$emit('stateChanged');
        Vue.prototype.$noty.success('Successfully changed');
      }).catch(e => {
        Vue.prototype.$noty.error(`Issues found while changing status ${e.message}`);
      }).finally(() => {
        this.saving = false;
      });
    },
    formatDatetime(datetime) {
      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (e) {
        return datetime;
      }
    },
  },
};
</script>
