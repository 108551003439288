<template>
  <div class="row" style="padding: 0px 20px 0px 20px;">
    <div class="col-md-12">
      <table class="table" v-if="events.length > 0">
        <thead class="thead-light">
        <tr class="row">
          <th scope="col" class="col-md-4"><b>Updated At</b></th>
          <th scope="col" class="col-md-4"><b>Type</b></th>
          <th scope="col" class="col-md-4"><b>Status</b></th>
        </tr>
        </thead>
        <tbody>
        <tr class="row" v-for="event in events" :key="event.id">
          <td class="col-md-4">{{ formatDatetime(event.updated_at) }}</td>
          <td class="col-md-4">{{ event.achievement_type}}</td>
          <td class="col-md-4 events-status-colors">
            <span :class="[event.status, 'events-status']">{{ event.status }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!is_synchronizing">
      <b-button @click="syncEvents" variant="primary" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          <span v-else>Sync Ecosystem Events</span>
      </b-button>
    </div>
    <div class="d-flex flex-row" v-if="is_synchronizing">
      <b-spinner class="mb-2" label="Loading..." variant="info" size="lg" ></b-spinner>
      <p style="margin-left: 10px; margin-top: 5px;">Synchronizing ecosystem events. Please refresh this page in few seconds</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'ecosystem-events',
  data() {
    return {
      is_synchronizing: false,
      loading: false,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    syncEvents() {
      this.loading = true;
      axios.post(`/v1/patients/${this.user.program_uuid}/sync-ecosystem-events`).then(data => {
        const resp = data.data;
        if (!resp.success) {
          Vue.prototype.$noty.error(resp.message);
          return;
        }
        this.is_synchronizing = true;
        Vue.prototype.$noty.success('Successfully changed');
      }).catch(e => {
        Vue.prototype.$noty.error(`Issues found while sync events ${e.message}`);
      }).finally(() => {
        this.loading = false;
      });
    },
    formatDatetime(datetime) {
      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (e) {
        return datetime;
      }
    },
  },
};
</script>

<style lang="scss">
  @import '../../../styles/ecosystemEvents.scss';
</style>
